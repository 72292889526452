<template>
    <member-avatar
        v-if="readOnly"
        :size="size"
        :item="member"
    />
    <v-sheet
        v-else
        :width="size"
        :height="size"
        color="transparent"
    >
        <media-image-input
            :src.sync="member.avatar"
            :rounded="rounded"
            :cropper-props="{zoomable: false}"
            @upload="onUploadAvatar"
            @remove="onUploadAvatar"
        >
            <template #activator="{on}">
                <member-avatar
                    :size="size"
                    :item="member"
                    :rounded="rounded"
                    :on="on"
                />
                <v-avatar
                    :style="emptyBtnStyle"
                    :size="Number(size) < 90 ? 24 : 42"
                    v-on="on"
                    class="cursor-pointer actions-activator"
                >
                    <v-icon
                        color="secondary"
                        :small="Number(size) < 90"
                    >
                        mdi-pencil
                    </v-icon>
                </v-avatar>
            </template>
        </media-image-input>
    </v-sheet>
</template>

<script>
/* eslint-disable */
import MemberAvatar from '@/components/Member/MemberAvatar'
import MediaImageInput from '@apps/media/components/MediaImageInput'
import { updateMember } from '@apps/auth/api'
export default {
    name: 'MemberAvatarForm',
    components: { MediaImageInput, MemberAvatar },
    props: {
        member: {
            type: Object,
            required: true
        },
        memberType: {
            type: String,
            required: true
        },
        rounded: {
            type: [Boolean,String],
            default: false
        },
        size: {
            type: [String, Number],
            default: 140
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        roundedComputed() {
            if(typeof this.rounded === 'string') {
                return `rounded-${this.rounded}`
            }

            return this.rounded ? 'rounded' : null
        },
        emptyBtnStyle() {
            const size = Number(this.size)

            return {
                position: 'absolute',
                bottom: size < 90 ? '-5px' : '8px',
                right: size < 90 ? '-5px' : '8px'
            }
        }
    },
    methods: {
        async onUploadAvatar(media) {
            this.$lprogress.begin()

            const avatarSrc = media
                ? media.src
                : null

            try {
                this.$notification.disabled()
                await updateMember(this.memberType, this.member.uuid, {
                    avatar: avatarSrc
                })
            } finally {
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>
.member-avatar {
    position: relative;

    .activator-icon {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &__action {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,0.5);
        box-shadow: rgba(0,0,0,.45) 0 0 1px;
        width: 100%;
        text-align: center;
    }

    .actions-activator {
        background-color: rgba(255, 255, 255, .85);
        box-shadow: rgba(0,0,0,.45) 0 0 1px;
    }
}

</style>
