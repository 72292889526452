<template>
    <v-dialog
        :value="dialog"
        width="450"
        scrollable
        persistent
        @input="flag => $emit('input', flag)"
    >
        <template v-slot:activator="{attrs, on}">
            <slot
                name="activator"
                :on="on"
                :attrs="attrs"
            />
        </template>
        <v-card class="media-image-cropper">
            <v-card-title class="justify-center text-h6">
                {{ $trans('Change') }}
            </v-card-title>
            <v-divider/>
            <v-card-text
                class="grey darken-1 pa-6"
                style="max-height: 100vh;"
            >
                <vue-cropper
                    :key="`cropperKey-${cropperKey}`"
                    ref="cropper"
                    :src="srcComputed"
                    alt="Source Image"
                    v-bind="cropperPropsComputed"
                    :aspect-ratio="aspectRatioComputed"
                />
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn
                    icon
                    color="primary"
                    @click="onRotate(-90)"
                >
                    <v-icon>mdi-restore</v-icon>
                </v-btn>
                <v-btn
                    icon
                    color="primary"
                    @click="onRotate(90)"
                >
                    <v-icon>mdi-reload</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="onCancel"
                >
                    {{ $trans('Cancel') }}
                </v-btn>
                <v-btn
                    depressed
                    color="primary"
                    @click="onAccept"
                >
                    OK
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { getFile } from '@apps/media/api'

export default {
    name: 'MediaImageCropper',
    components: { VueCropper },
    props: {
        value: Boolean,
        src: {
            type: String,
            default: null
        },
        aspectRatio: {
            type: String,
            default: '1:1'
        },
        cropperProps: {
            type: Object,
            default: null
        }
    },
    computed: {
        aspectRatioComputed() {
            const ratio = this.aspectRatio.split(':')

            if(ratio.length === 1 || ratio.length > 2) {
                return 1
            }

            return Number(ratio[0]) / Number(ratio[1])
        },
        srcComputed() {
            return getFile(this.src)
        },
        cropperPropsComputed() {
            const props = {
                guides: true,
                autoCropArea: 1,
                viewMode: 1,
            }

            const cropperProps = this.cropperProps

            if(cropperProps) {
                return Object.assign(props, cropperProps)
            } else {
                return props
            }
        }
    },
    watch: {
        value(flag) {
            this.dialog = flag
        },
        src() {
            this.cropperKey++
        }
    },
    data: () => ({
        cropperKey: 0,
        dialog: false
    }),
    methods: {
        onRotate(deg) {
            this.$refs.cropper.rotate(deg)
        },
        onAccept() {
            const croppedCanvas = this.$refs.cropper.getCroppedCanvas()

            this.$emit('input', false)
            this.$emit('accept', { croppedCanvas })
        },
        onCancel() {
            this.$emit('input', false)
            this.$emit('cancel')
        }
    }
}
</script>

<style lang=scss>
.media-image-cropper {
    .v-card__text {
        width: 100%;
    }
}
</style>
