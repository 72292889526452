<template>
    <div
        class="media-image-input"
        :class="isEditing ? 'media-image-input--editing' : ''"
    >
        <label v-if="label">{{ label }}</label>
        <input
            ref="inputMediaImagePreview"
            type="file"
            accept="image/png, image/jpeg"
            class="d-none"
            @change="onSelectImage"
        />
        <div
            class="media-image-input-preview"
        >
            <v-img
                v-if="isEditing && previewSrc"
                :src="getFile(previewSrc)"
                :aspect-ratio="aspectRatioComputed"
                width="100%"
                :class="roundedComputed"
            />
            <div :style="editMenuWrapperStyle">
                <w-actions-menu
                    v-if="isEditing"
                    :items="editActions"
                >
                    <template #activator="{attrs, on}">
                        <v-btn
                            v-bind="attrs"
                            icon
                            small
                            v-on="on"
                            class="actions-activator"
                        >
                            <v-icon
                                :size="18"
                                color="secondary"
                            >
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                </w-actions-menu>
                <slot
                    v-else
                    name="activator"
                    :on="{ click: onFocusInputImage }"
                >
                    <v-btn

                        dense
                        color="primary"
                        depressed
                        @click="onFocusInputImage"
                    >
                        <v-icon
                            color="white"
                            left
                            size="20"
                        >
                            mdi-plus-circle-outline
                        </v-icon>
                        {{ uploadText }}
                    </v-btn>
                </slot>
            </div>
            <div
                v-if="loading"
                class="media-image-input-loader"
            >
                <v-progress-circular
                    indeterminate
                    color="primary"
                    size="20"
                />
            </div>
        </div>
        <media-image-cropper
            v-model="isCropper"
            :src="previewSrc"
            :aspect-ratio="aspectRatio"
            :cropper-props="cropperProps"
            @accept="onAcceptCrop"
            @cancel="onCancel"
        />
        <v-dialog
            v-model="invalidImageDialog"
            persistent
            width="470"
        >
            <v-card>
                <v-card-title>
                    <v-icon color="warning" left>mdi-alert</v-icon>
                    {{ $trans('media.large_image_dialog.title') }}
                </v-card-title>
                <v-divider/>
                <v-card-text class="mt-4" style="width: 100%">
                    <div>{{ $trans('media.large_image_dialog.options_label') }}:</div>
                    <ul>
                        <li>{{ $trans('media.large_image_dialog.options.max_width', { maxWidth }) }}</li>
                        <li>{{ $trans('media.large_image_dialog.options.max_height', { maxHeight }) }}</li>
                        <li>{{ $trans('media.large_image_dialog.options.max_size', { maxSize }) }}</li>
                    </ul>
                    <div>{{ $trans('media.large_image_dialog.suggestion') }}</div>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        text
                        color="primary"
                        @click="invalidImageDialog = false"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackbar.state"
            :color="snackbar.color"
        >
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    :color="snackbar.btnColor"
                    text
                    v-bind="attrs"
                    @click="snackbar.state = false"
                >
                    {{ $trans('Close') }}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
/* eslint-disable */
import MediaImageCropper from './MediaImageCropper'
import { getFile, getFileInfo, removeFile, uploadFile } from '../api'

export default {
    name: 'MediaImageInput',
    components: { MediaImageCropper },
    props: {
        value: File,
        aspectRatio: {
            type: String,
            default: '1:1'
        },
        label: {
            type: String,
            default: null
        },
        alt: {
            type: String,
            default: null
        },
        src: {
            type: String,
            default: null
        },
        rounded: {
            type: [ Boolean, String ],
            default: false
        },
        uuid: {
            type: String,
            default: null
        },
        uploadText: {
            type: String,
            default: 'Add cover image'
        },
        maxSize: {
            type: [ Number, String ],
            default: 0
        },
        maxWidth: {
            type: [ Number, String ],
            default: 0
        },
        maxHeight: {
            type: [ Number, String ],
            default: 0
        },
        cropperProps: {
            type: Object,
            default: null
        }
    },
    computed: {
        editMenuWrapperStyle() {
            const rounded = !!this.rounded

            return this.isEditing ?
                {
                    position: 'absolute',
                    bottom: rounded ? '-8px' : '8px',
                    right: rounded ? '-8px' : '8px'
                } : {}
        },
        roundedComputed() {
            if(typeof this.rounded === 'string') {
                return `rounded-${ this.rounded }`
            }

            return this.rounded ? 'rounded' : null
        },
        isEditing() {
            return !!this.previewSrc
        },
        aspectRatioComputed() {
            const ratio = this.aspectRatio.split(':')

            if(ratio.length === 1 || ratio.length > 2) {
                return 1
            }

            return Number(ratio[0]) / Number(ratio[1])
        },
        imgFileName() {
            if(this.imgFile) {
                return this.imgFile.name
            }

            if(this.imgInfo) {
                return this.imgInfo.title
            }

            return null
        },
        imgFileType() {
            if(this.imgFile) {
                return this.imgFile.type
            }

            if(this.imgInfo) {
                return this.imgInfo.mime_type
            }

            return null
        },
        editActions() {
            return [
                {
                    text: this.$trans('Change'),
                    icon: 'EDIT',
                    color: 'primary',
                    on: {
                        click: this.onCrop
                    }
                },
                {
                    text: this.$trans('Delete'),
                    icon: 'CLOSE',
                    color: 'error',
                    on: {
                        click: this.onDeleteImage
                    }
                }
            ]
        }
    },
    watch: {
        async src(src) {
            this.previewSrc = src
            await this.getFileInfo()
        }
    },
    async mounted() {
        this.previewSrc = this.src
        await this.getFileInfo()
    },
    data() {
        return {
            invalidImageDialog: false,
            isCropper: false,
            previewSrc: null,
            imgInfo: null,
            imgFile: null,
            loading: false,
            snackbar: {
                state: false,
                color: 'error',
                btnColor: 'pink',
                text: ''
            }
        }
    },
    methods: {
        getFile(src) {
            return getFile(src)
        },
        onFocusInputImage() {
            this.$refs.inputMediaImagePreview.value = null
            this.imgFile = null

            this.$refs.inputMediaImagePreview.click()
        },
        async getFileInfo() {
            if(!this.src) return

            try {
                const response = await getFileInfo(this.src)

                this.imgInfo = response.data.data
            } catch (error) {
                console.log(error)
            }
        },
        onSelectImage(e) {
            const file = e.target.files[0]

            if(!file) {
                return
            }

            const imageValidation = file => {
                let isValid = true
                const maxSize = this.maxSize

                if(maxSize > 0 && Math.round(file.size / 1000) > maxSize) {
                    return false
                }

                const image = new Image()
                image.src = window.URL.createObjectURL(file)
                const maxWidth = Number(this.maxWidth)
                const maxHeight = Number(this.maxHeight)

                image.onload = () => {
                    if(maxWidth > 0 && image.width > Number(maxWidth)) {
                        isValid = false
                    } else if(maxHeight > 0 && image.height > Number(maxHeight)) {
                        isValid = false
                    }
                }

                return isValid
            }

            const isValid = imageValidation(file)

            if(!isValid) {
                this.invalidImageDialog = false
                this.invalidImageDialog = true

                return
            }

            this.imgFile = file

            this.previewSrc = null
            this.previewSrc = window.URL.createObjectURL(file)
            this.isCropper = true
        },
        onCrop() {
            if(this.imgFile) {
                this.previewSrc = window.URL.createObjectURL(this.imgFile)
            }

            this.isCropper = true
        },
        async onAcceptCrop({ croppedCanvas }) {
            if(!this.imgFileName && !this.imgFileType) {
                const message = 'MediaImageInput: error get file info'

                this.snackbar.text = message
                this.snackbar.state = true

                throw new Error(message)
            }

            this.loading = true

            croppedCanvas.toBlob(async (blob) => {
                try {
                    if(this.src) {
                        this.$notification.disabled()
                        await removeFile(this.src)
                    }

                    const file = new File([ blob ], this.imgFileName, { type: this.imgFileType })
                    this.previewSrc = window.URL.createObjectURL(file)

                    const attachment = true

                    this.$notification.disabled()
                    const uploaded = await uploadFile({
                        file,
                        attachment
                    })

                    this.imgInfo = uploaded.data.data
                    const src = uploaded.data.data.src

                    this.$emit('update:src', src)
                    this.$emit('upload', this.imgInfo)
                } catch (error) {
                    console.log(error)
                } finally {
                    this.loading = false
                }
            }, this.imgFileType)
        },
        onCancel() {
            // this.$refs.inputMediaImagePreview.value = null
            // this.imgFile = null
            this.previewSrc = this.src
        },
        async onDeleteImage() {
            this.loading = true

            try {
                if(this.src) {
                    this.$notification.disabled()
                    await removeFile(this.src)
                }

                this.$refs.inputMediaImagePreview.value = null
                this.imgFile = null
                this.previewSrc = null

                this.$emit('update:src', null)
                this.$emit('remove')
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang=scss scoped>
.media-image-input {
    position: relative;

    label {
        width: 100%;
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        margin-bottom: 8px;
    }

    .media-image-input-preview {
        position: relative;
    }

    .media-image-input-loader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        z-index: 1;
        background-color: rgba(255, 255, 255, 0.4);

        > * {
            position: absolute;
            left: calc(50% - 20px);
            top: calc(50% - 20px);
        }
    }

    .actions-activator {
        background-color: rgba(255, 255, 255, .85);
        box-shadow: rgba(0, 0, 0, .45) 0 0 1px;
    }
}
</style>
